export default [
  {
    title: "Templates",
    icon: "HomeIcon",
    route: "templates-home-page",
    resource: 'Templates',
    action:'read'
  },
  {
    title: "History",
    icon: "ClockIcon",
    route: "genration-history",
    resource: 'Templates',
    action:'read'
  },
  {
    title: "Reports",
    icon: "CpuIcon",
    route: "user-reports",
    resource: 'Report',
    action:'read'
  },
  {
    title: "Settings",
    icon: "SettingsIcon",
    route: "apps-users-edit",
    resource: 'Auth',
    action:'read'
  },
];
