import dashboard from "./dashboard";
import appsAndPages from "./apps-and-pages";
// import chartsAndMaps from "./charts-and-maps";
// import uiElements from "./ui-elements";
// import formAndTable from "./forms-and-table";

// Array of sections
export default [
  ...dashboard,
  ...appsAndPages,
  //   ...uiElements,
  //   ...formAndTable,
  //   ...chartsAndMaps,
];
