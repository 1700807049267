export default [
  {
    title: "Dashboards",
    icon: "BoxIcon",
    route: "dashboard-analytics",
    resource: 'Auth',
    action:'read'

  },
];
