var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav-item-dropdown',{staticClass:"dropdown-user",attrs:{"right":"","toggle-class":"d-flex align-items-center dropdown-user-link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-sm-flex d-none user-nav"},[_c('p',{staticClass:"user-name font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.userData.name || _vm.userData.username)+" ")]),_c('span',{staticClass:"user-status"},[_vm._v(_vm._s(_vm.userData.role))])]),_c('b-avatar',{staticClass:"badge-minimal",attrs:{"size":"40","src":_vm.userData.avatar,"variant":"light-primary","badge":"","badge-variant":"success"}},[(!_vm.userData.name)?_c('feather-icon',{attrs:{"icon":"UserIcon","size":"22"}}):_vm._e()],1)]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{ name: 'dashboard-analytics', query: _vm.$route.query }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"BoxIcon"}}),_c('span',[_vm._v("Dashboard")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{
      path: '/history',
      query: _vm.$route.query,
      name: 'genration-history',
    }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"ClockIcon"}}),_c('span',[_vm._v("History")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{
      query: _vm.$route.query,
      name: 'user-reports',
    }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"CpuIcon"}}),_c('span',[_vm._v("Reports")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{
      path: '/apps/users/edit/',
      query: _vm.$route.query,
      name: 'apps-users-edit',
    }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"SettingsIcon"}}),_c('span',[_vm._v("Settings")])],1),(_vm.userData.role !='GOOGLE_USER')?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.resetPassword(_vm.userData)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"HashIcon"}}),_c('span',[_vm._v("Reset password")])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.logout}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v("Logout")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }