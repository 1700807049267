<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'dashboard-analytics', query: $route.query }"
    >
      <feather-icon size="16" icon="BoxIcon" class="mr-50" />
      <span>Dashboard</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{
        path: '/history',
        query: $route.query,
        name: 'genration-history',
      }"
    >
      <feather-icon size="16" icon="ClockIcon" class="mr-50" />
      <span>History</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{
        query: $route.query,
        name: 'user-reports',
      }"
    >
      <feather-icon size="16" icon="CpuIcon" class="mr-50" />
      <span>Reports</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{
        path: '/apps/users/edit/',
        query: $route.query,
        name: 'apps-users-edit',
      }"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" v-if="userData.role !='GOOGLE_USER'" @click="resetPassword(userData)">
      <feather-icon size="16" icon="HashIcon" class="mr-50" />
      <span>Reset password</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  created() {
    this.$store.dispatch("appConfig/updateUserQuota");
  },
  methods: {
    resetPassword(data) {
      this.$store
        .dispatch("userSettings/getResetPasswordToken", data)
        .then((response) => {
          this.$router.push({
            name: "auth-reset-password",
            query: $route.query,
            params: {
              token: response.data.resetPasswordToken
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error happened",
              icon: "AlertTriangleIcon",
              variant: "light-danger",
              text: `Something went wrong`,
            },
          });
          console.log(e);
        });
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      //Remove current user data from store
      this.$store.commit("authStore/setCurrentUser", {}); //set current user data

      //clean categories state
      this.$store.dispatch("templatesStore/cleanState");

      // Reset ability
      this.$ability.update(initialAbility);
      
      // Redirect to login page
      this.$router.push({ name: "auth-login", query: this.$route.query });
    },
  },
};
</script>
